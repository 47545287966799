
import TokenStorageService from "../services/tokenStorageService";
import { getNewToken } from "../api/auth";


const _tokenStorageService = new TokenStorageService();

class AuthService {
	constructor() {

	}

	getAccessToken() {
		return _tokenStorageService.getToken();
	}
	isAccessTokenExists() {
		return _tokenStorageService.isAccessTokenExists();
	}

	isAccessTokenExpired() {
		return _tokenStorageService.isAccessTokenExpired();
	}

	tryUpdateAccessToken = async () => {
		return await this.login(_tokenStorageService.getRefreshToken());
	};

	login = async (token) => {
		let { accessToken, refreshToken, expiresIn } = await getNewToken(token);
		console.log({ accessToken, refreshToken, expiresIn })
		_tokenStorageService.storeToken(accessToken, expiresIn);
		_tokenStorageService.storeRefreshToken(refreshToken);
		return accessToken;
	};

	logout = (callback) => {
		_tokenStorageService.clear();

		if (callback)
			callback();
	};
}

export default new AuthService();
