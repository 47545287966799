<template>
	<v-app id="inspire">
		<v-main>
			<v-container fluid
				style="display: flex; flex-direction: column; align-items: center; justify-content: center; ">

				<v-row class="align-center justify-center">
					<v-col cols="12">
						<v-btn v-show="!phoneActive && !codeActive" @click="phoneActive = true" variant="text">{{
													$t("auth.buttons.login") }}</v-btn>
						<v-card v-show="phoneActive" class="align-center justify-center col-12" width="400px">
							<v-card-title class="justify-center">
								<div class="d-flex justify-center  col col-12">
									<div>
										<v-img width="155px" height="50px" cover src="../assets/logo.svg"></v-img>
									</div>
								</div>
							</v-card-title>
							<v-form ref="form" v-model="PhoneValid">
								<v-container>
									<v-row class="mt-4">
										<v-col>
											<v-text-field outlined v-model="phone" :rules="phoneRules"
												label="Номер телефона" required></v-text-field>
											<vue-recaptcha :sitekey="sitekey" ref="recaptcha"
												@verify="handleVerify"></vue-recaptcha>

										</v-col>
									</v-row>
								</v-container>
							</v-form>
							<v-row class="align-self-end col-12 mb-2">
								<v-col class="d-flex justify-end col-12 mr-4">
									<v-btn :disabled="!PhoneValid || reCapthaCode === ''" color="success"
										@click="verifyPhone">
										{{ $t("auth.buttons.continue") }}
									</v-btn>
								</v-col>
							</v-row>
						</v-card>

						<v-card v-show="codeActive" class="align-center justify-center col-12" width="400px">
							<v-card-title class="justify-center">
								<div class="d-flex justify-center  col col-12">
									<div>
										<v-img width="155px" height="45px" cover src="../assets/logo.svg"></v-img>
									</div>
								</div>
							</v-card-title>
							<v-card-title class="justify-center">
								{{ $t("auth.titles.approveNumber") }}
							</v-card-title>
							<v-form ref="form" v-model="CodeValid">
								<v-container>
									<v-row>
										<v-col>
											<v-text-field outlined v-model="code" :rules="codeRules" label="Введите код"
												required></v-text-field>
										</v-col>
									</v-row>
								</v-container>

							</v-form>
							<p style="font-size: 14px; color: gray; margin-left: 15px;">{{ $t("auth.titles.noSMS") }}
								<a @click="tryAgain" style="color: #304FFE; margin-left: 5px; cursor: pointer;">{{
																	$t("auth.titles.tryAgain") }}</a>
							</p>
							<v-row class="align-self-end col-12 mb-2 mt-2">
								<v-col class="d-flex justify-end col-12 mr-4">
									<v-btn :disabled="!CodeValid" color="success" @click="login">
										{{ $t("auth.buttons.check") }}
									</v-btn>
								</v-col>
							</v-row>
						</v-card>

					</v-col>
				</v-row>
			</v-container>
			<fartsa-alerts></fartsa-alerts>

		</v-main>
	</v-app>
</template>

<script>

import authService from "../services/authService";
import urls from "../api/config/urls";
import axios from "axios";
import { mutationTypes as alertMutationTypes } from "Store/modules/alerts/types";
import Alerts from "Views/base/Alerts";
import { createNamespacedHelpers } from "vuex";
import { namespace as alertsStoreNamespace } from "Store/modules/alerts";
import AlertModel from "Store/modules/alerts/models/alertModel";
import vueRecaptcha from 'vue3-recaptcha2';
import createUrlParam from '../utils/createUrlParam'
import createUuidv4 from '../utils/createUuid'




const alertsModule = createNamespacedHelpers(alertsStoreNamespace);


export default {
	data() {
		return {
			PhoneValid: true,
			CodeValid: true,
			phoneActive: false,
			codeActive: false,
			sitekey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
			phone: '+7',
			reCapthaCode: '',
			phoneRules: [
				v => !!v || 'Phone is required',
				v => /^\+7[0-58-9]\d{9}$/.test(v) || 'Phone is incorrect'
			],
			code: '',
			codeRules: [
				v => !!v || 'code is required',

			],
			sessionId: createUuidv4()


		};
	},
	name: "sl-auth",
	methods: {
		...alertsModule.mapMutations({
			addErrorAlert: alertMutationTypes.ADD_ERROR_ALERT
		}),
		handleVerify(response) {
			this.reCapthaCode = response
		},
		async verifyPhone() {
			try {
				createUrlParam('sessionId', this.sessionId)
				await axios.post(urls.auth.verifyPhone, {}, {
					params: {
						phone: this.phone, captcha: this.reCapthaCode, sessionId: this.sessionId
					}
				})
				this.phoneActive = false
				this.codeActive = true
			} catch (error) {
				this.addErrorAlert(new AlertModel({ text: this.$t("auth.errors.authError") }));
				console.log(error)

			}

		},
		tryAgain() {
			this.codeActive = false
			this.code = ''
			this.phone = '+7'
			this.phoneActive = true
		},
		async login() {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const sessionId = urlParams.get('sessionId')

				const response = await axios.post(urls.auth.login, {}, {
					params: {
						phone: this.phone, code: this.code, sessionId
					}
				})
				const { data } = response;
				const idToken = await data.token;
				authService.login(idToken).then(() => this.$router.push({ name: 'Home' }))
			} catch (error) {
				this.addErrorAlert(new AlertModel({ text: this.$t("auth.errors.authError") }));
				console.error(error);

			}

		}
	},
	components: {
		"fartsa-alerts": Alerts,
		vueRecaptcha,
	},

};
</script>

<style scoped></style>
