import { createStore } from 'vuex'

import alertsStore from "Store/modules/alerts";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    [alertsStore.namespace]: {
      namespaced: true,
      ...alertsStore
    },
  }
})
