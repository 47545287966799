<template>
	<v-snackbar v-model="isShown" :color="color" :timeout="timeout">
		{{ text }}

		<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="close">
				<template>
					<v-icon>mdi-close</v-icon>
				</template>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: "fartsa-alert-snackbar",
	props: {
		color: String,
		text: String,
		timeout: Number
	},
	data() {
		return {
			isShown: true
		};
	},
	watch: {
		isShown(newValue) {
			if (!newValue) {
				this.close();
			}
		}
	},
	methods: {
		close() {
			this.$emit("close");
		}
	}
};
</script>

<style scoped></style>
