const fartcaApiBaseUrl = process.env.VUE_APP_BASE_API_URL
const fartcaApiDocumentsUrl = process.env.VUE_APP_DOCUMENTS_API_URL
const fartcaAuthUrl = process.env.VUE_APP_AUTH_SMS_URL


const urls = {
	order: {
		getOrder: `${fartcaApiBaseUrl}/Statusing/request`,
		putResult: `${fartcaApiBaseUrl}/Statusing/request`,
		getDocuments: `${fartcaApiDocumentsUrl}/documents/info/{inn}/{requestId}`,
		getLinkToDocument: `${fartcaApiDocumentsUrl}/documents/{id}`

	},
	auth: {
		getRefreshToken: `${fartcaApiBaseUrl}/connect/token`,
		verifyPhone: `${fartcaAuthUrl}/Auth/sms`,
		login: `${fartcaAuthUrl}/Auth/code`,
	},
	transacions: {
		getTransactions: `${fartcaApiBaseUrl}/Finance/transactions/unprocessed`,
	},
	adminPanel: {
		searhUser: `${fartcaApiBaseUrl}/ambassador/search?searchText={searchText}`,
		getAmbassadorProfile: `${fartcaApiBaseUrl}/ambassador/profile?userId={userId}`,
		getAmbassadorBeneficiary: `${fartcaApiBaseUrl}/ambassador/beneficiary?userId={userId}`,
		getAmbassadorTransactions: `${fartcaApiBaseUrl}/ambassador/transactions/finance?userId={userId}`,
		getAmbassadorPromocodes: `${fartcaApiBaseUrl}/ambassador/promocodes?userId={userId}`,
	}


}

export default urls
