export default {
	buttons: {
		login: {
			ru: 'login'
		},
		continue: {
			ru: 'Продолжить'
		},
		check: {
			ru: 'Проверить'
		}

	},
	titles: {
		approveNumber: {
			ru: 'Подтвердите номер'
		},
		noSMS: {
			ru: 'Не получили SMS?'
		},
		tryAgain: {
			ru: 'Попробовать еще раз'
		}
	},
	errors: {
		authError: {
			ru: 'Произошла ошибка, повторите попытку'
		}

	}
}