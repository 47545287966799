import authService from "Services/authService";

const addAuthGuard = ({ router }) => {


	router.beforeEach((to, from, next) => {

		if (!to.matched.some(record => record.meta.requiresAuth) ||
			(authService.isAccessTokenExists() && (!authService.isAccessTokenExpired() || authService.tryUpdateAccessToken()))) {
			next();
		} else {
			next({
				path: "/login"
			});
		}
	});
};

export default addAuthGuard;