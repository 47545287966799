<template>
	<div class="d-flex align-center flex-column justify-center">
		<v-card class="mt-3 " v-show="anyRequests" max-width="600px">
			<v-card-title v-if="legalEntity.type != 4" style="font-size: 16px;" class="text-wrap">
				{{ `${userType(legalEntity.type)} ${legalEntity.lastName} ${legalEntity.firstName}
				${legalEntity.middleName}` }}
			</v-card-title>
			<v-card-title v-if="legalEntity.type == 4" style="font-size: 16px;" class="text-wrap">
				{{ legalEntity.name }}
			</v-card-title>

			<v-divider></v-divider>

			<v-card-text v-if="legalEntity.type != 4 && legalEntity.type != 3" class="d-flex"
				style="flex-direction: column;">
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.inn") }}: {{ legalEntity.inn
					}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.addressRegistration") }}: {{
			legalEntity.addressRegistration
		}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.dateOfBirth") }}: {{ legalEntity.dateOfBirth
					}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.documentNumber") }}: {{
			legalEntity.documentNumber
		}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.documentIssuedBy") }}: {{
			legalEntity.documentIssuedBy
		}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.documentIssuedByCode") }}: {{
			legalEntity.documentIssuedByCode
		}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.documentIssuedDateAt") }}: {{
			legalEntity.documentIssuedDateAt
		}}</v-card-subtitle>
			</v-card-text>
			<v-card-text v-if="legalEntity.type == 4 || legalEntity.type == 3" class="d-flex"
				style="flex-direction: column;">
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.inn") }}: {{ legalEntity.inn
					}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.ogrn") }}: {{ legalEntity.ogrn
					}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.registrationDate") }}: {{
			legalEntity.registrationDate
		}}</v-card-subtitle>
				<v-card-subtitle class="text-wrap">{{ $t("order.subtitles.address") }}: {{ legalEntity.address
					}}</v-card-subtitle>
			</v-card-text>
			<v-card-text>
				<div v-for="document in documentsInfo">
					<div style="display: flex; flex-direction: row; align-items: center;">
						<v-card-subtitle>{{ documentTitle(document.type) }}:</v-card-subtitle>
						<v-btn v-bind:ripple="false" @click="getDocument(document.id, document.type)" variant="text"
							density="compact" icon="mdi-file-download"></v-btn>
					</div>
					<v-spacer></v-spacer>
				</div>
			</v-card-text>
			<v-col>
				<v-textarea v-model="comment" auto-grow label="Комментарий к заявке" rows="3" row-height="25"
					class="mt-6"></v-textarea>
			</v-col>


			<v-card-actions>
				<v-row class=" align-self-end px-3 justify-center">
					<v-col class="d-flex justify-start pb-16 col-md-5 col-xl-4 col-6">
						<v-btn :disabled="comment === ''" @click="putResult(1)" color="error">
							{{ $t("order.buttons.deny") }}
						</v-btn>
					</v-col>
					<v-col class="d-flex justify-end pb-16 col-md-5 col-xl-4 col-6">
						<v-btn @click="putResult(0)" color="success">
							{{ $t("order.buttons.approve") }}
						</v-btn>
					</v-col>
				</v-row>

			</v-card-actions>
		</v-card>
		<v-card class="mt-3" width="600" v-show="!anyRequests">
			<v-card-title style="font-size: 16px; text-align: center;" class="text-wrap">
				{{ $t("order.titles.noBids") }}
			</v-card-title>
		</v-card>

	</div>
	<fartsa-alerts></fartsa-alerts>
</template>





<script>
import urls from '../api/config/urls'
import legalEntityCompanyModel from '../api/legalEntities/legalEntityCompanyModel'
import { mutationTypes as alertMutationTypes } from "Store/modules/alerts/types";
import Alerts from "Views/base/Alerts";
import { createNamespacedHelpers } from "vuex";
import { namespace as alertsStoreNamespace } from "Store/modules/alerts";
import AlertModel from "Store/modules/alerts/models/alertModel";
import apiClient from '@/api/client/apiClient';
import axios from "axios";
import { getHeaders } from "Api/config/headers";




const alertsModule = createNamespacedHelpers(alertsStoreNamespace);

export default {
	data: () => (
		{
			legalEntity: legalEntityCompanyModel.empty(),
			comment: '',
			statusId: null,
			id: null,


			documentsInfo: [],
			anyRequests: false,
		}
	),
	methods: {
		...alertsModule.mapMutations({
			addErrorAlert: alertMutationTypes.ADD_ERROR_ALERT
		}),
		async getOrder() {
			try {
				const response = await apiClient.get({ url: urls.order.getOrder })


				if (response) {
					this.anyRequests = true
					this.legalEntity = response.legalEntity
					this.statusId = response.statusId
					this.id = response.id
				} else {
					this.anyRequests = false

				}
			} catch (error) {
				this.addErrorAlert(new AlertModel({ text: this.$t("order.errors.orderError") }));
				console.log(error)

			}
		},
		async putResult(status) {
			this.isSubmitLoading = true
			try {
				await apiClient.put({ url: urls.order.putResult, payload: { id: this.id, status, note: this.comment } })
				this.comment = ''
				this.documentsInfo = []
				await this.getOrder()
				if (this.anyRequests) {
					await this.getLinkToDocument()
				}
			} catch (error) {
				this.addErrorAlert(new AlertModel({ text: this.$t("order.errors.orderError") }));
				console.log(error)
			}
		},
		async getLinkToDocument() {
			try {
				const result = await apiClient.get({ url: urls.order.getDocuments.replace('{inn}', this.legalEntity.inn).replace('{requestId}', this.statusId) })
				this.documentsInfo = result
			} catch (error) {
				this.addErrorAlert(new AlertModel({ text: this.$t("order.errors.orderError") }));
				console.log(error)

			}
		},
		async getDocument(id, type) {
			try {

				await axios.get(urls.order.getLinkToDocument.replace('{id}', id), {
					headers: getHeaders(),
					responseType: 'arraybuffer',
				}).then(response => {
					console.log(response)
					if ((type != 4 && type != 6)) {
						const blob = new Blob([response.data], { type: response.headers.getContentType() });
						const blobURL = URL.createObjectURL(blob);
						window.open(blobURL, '_blank');
					} else {
						const blob = new Blob([response.data], { type: response.headers.getContentType() });
						const blobURL = URL.createObjectURL(blob);
						const link = document.createElement('a');
						console.log(type)
						link.href = blobURL;
						if (type == 4) {
							link.setAttribute('download', id);
						} else {
							link.setAttribute('download', id + '.sig');
						}

						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}

				})
			} catch (error) {
				this.addErrorAlert(new AlertModel({ text: this.$t("order.errors.orderError") }));
				console.log(error)
			}
		},
		documentTitle(type) {
			switch (type) {
				case 1: return this.$t("order.documents.innCercificate")
				case 2: return this.$t("order.documents.passport")
				case 3: return this.$t("order.documents.order")
				case 4: return this.$t("order.documents.SignedContract")
				case 5: return this.$t("order.documents.ogrnip")
				case 6: return this.$t("order.documents.signature")
				default: return ''
			}
		},
		userType(type) {
			switch (type) {
				case 1: return this.$t("order.userTypes.individual")
				case 2: return this.$t("order.userTypes.selfEmployed")
				case 3: return this.$t("order.userTypes.individualEntrepreneur")
				default: return ''
			}
		}


	},
	components: {
		"fartsa-alerts": Alerts,

	},
	async beforeMount() {
		try {
			await this.getOrder()
			if (this.anyRequests) {
				await this.getLinkToDocument()
			}

		} catch (error) {
			console.log(error)
		}


	}
}



</script>