import urls from "../api/config/urls";
import { stringify } from "qs";
import apiClient from "../api/client/apiClient";

const getNewToken = async (token) => {
	let res = await apiClient.post({
		url: urls.auth.getRefreshToken, payload: stringify({ grant_type: "Phone", client_id: process.env.VUE_APP_AUTH_CLIENT_ID, token }), headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Basic ${btoa(process.env.VUE_APP_AUTH_CLIENT_ID + ':')}`
		}
	});
	const {
		access_token: accessToken,
		refresh_token: refreshToken,
		expires_in: expiresIn
	} = res;

	return {
		accessToken, refreshToken, expiresIn
	};
};

export {
	getNewToken
};