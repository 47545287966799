<template>
	<v-app id="inspire">

		<v-app-bar app color="#f0f2f5" dark>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" color="grey"></v-app-bar-nav-icon>
			<v-toolbar-title style="width: 300px" class="pa-0 d-flex justify-center justify-md-start" color="#9c9c9c">

				<v-btn flat color="transparent" background="none" href="/" height="50px" variant="text">
					<v-img item width="155px" height="45px" cover src="../../assets/logo.svg" alt="Fartsa"></v-img>
				</v-btn>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon color="black" @click="logout()" href="/login">
				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app>
			<v-list dense>
				<template v-for="(item, j) in items">
					<v-list-group v-if="item.children" :id="item.id" :key="j" v-model="item.model"
						:prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
						<template v-slot:activator>

							<v-list-item-title>
								{{ item.text }}
							</v-list-item-title>

						</template>

						<v-list-item v-for="(child, i) in item.children" :to="child.routeConfig" :id="child.id" :key="i"
							class="pl-10" link>
							<div style="display: flex; flex-direction: row;">
								<v-list-item-action v-if="child.icon">
									<v-icon>{{ child.icon }}</v-icon>
								</v-list-item-action>

								<v-list-item-title>
									{{ child.text }}
								</v-list-item-title>
							</div>

						</v-list-item>
					</v-list-group>

					<v-list-item v-else :to="item.routeConfig" :class="{ 'd-none': !item.active }" link>
						<div style="display: flex; flex-direction: row;">
							<v-list-item-action>
								<v-icon>{{ item.icon }}</v-icon>
							</v-list-item-action>

							<v-list-item-title class="ml-5">
								{{ item.text }}
							</v-list-item-title>
						</div>

					</v-list-item>

				</template>
			</v-list>
		</v-navigation-drawer>

		<v-main>
			<v-container fluid class="pa-0 pa-sm-2">
				<v-row align="start" class="mx-0">
					<v-col cols="12" class="pt-0 px-0 px-sm-6 pt-sm-2">
						<router-view :key="$route.meta.key" />

					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>



<script>
import _authService from "Services/authService";


export default {
	data: () => ({
		logout: _authService.logout,
		drawer: false,
		group: null,
		items: [
			{ active: true, icon: "mdi-file-document-edit", text: 'Заявки', routeConfig: { name: "order" } },
			{ active: true, icon: 'mdi-bank', text: 'Операции', routeConfig: { name: "transactions" } },
			{ active: true, icon: 'mdi-account-eye', text: 'Статус амбассадора', routeConfig: { name: "userPanel" } }

		],
	}),

	watch: {
		group() {
			this.drawer = false
		},
	},
}
</script>


<style scoped></style>

