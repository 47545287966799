<template>
	<v-autocomplete label="Id || Email || ФИО || Телефон" :items="searchItems" item-title="fcs" item-value="userId"
		no-filter return-object v-model="searchText" @input="getUsersBySeachText">
		<template v-slot:item="{ props, item }">
			<v-list-item v-bind="props"
				:subtitle="`id: ${item.raw.userId} phone: ${item.raw.phone} email: ${item.raw.email}`"
				:title="item.raw.fcs" @click="setUserId(item.raw.userId)"></v-list-item>
		</template>

	</v-autocomplete>
	<v-container v-if="userId">
		<v-row no-gutters>
			<v-col class="d-flex " cols="4">
				<v-card class="pa-2 ma-2" width="100%">
					<v-card-item>
						<v-card-title>Юзер</v-card-title>


					</v-card-item>
					<v-card-item>
						<v-card-text>Статус: {{ userStatus(userData.isActive) }}</v-card-text>
						<v-card-text>ФИО: {{ userData.fcs }}</v-card-text>
						<v-card-text>ИНН: {{ userLegalEntity.inn }}
							<v-icon size="large" :color="legalEntityStatusTypeColor(userData.legalEntityStatus)">{{
		`${legalEntityStatusTypeIcon(userData.legalEntityStatus)} ` }}</v-icon></v-card-text>
						<v-card-text>Тип: {{ userType(userLegalEntity.type) }}</v-card-text>
						<v-card-text>Привязан банк: <v-icon :color="userBankAttachStatus(userData.bankAttached)"
								size="large">mdi-check-circle</v-icon></v-card-text>
						<v-card-text>Id бенефициара: {{ userBeneficiaryData.beneficiaryId ?
		userBeneficiaryData.beneficiaryId : 'Отсутствует' }}</v-card-text>
						<v-card-text>Id реквизитов: {{ userBeneficiaryData.bankDetailsId ?
		userBeneficiaryData.bankDetailsId
		: 'Отсутствует' }}</v-card-text>

					</v-card-item>

				</v-card>
			</v-col>

			<v-col class="d-flex" cols="8">
				<v-card class="pa-2 ma-2" width="100%">
					<v-card-item>
						<v-card-title>Статистика по промокодам</v-card-title>
						<v-data-table class="elevation-2 mb-2  mt-2" :items="formattedPromocodeTableItems"
							hide-default-footer :headers="promocodeTableHeaders"></v-data-table>
					</v-card-item>


				</v-card>
			</v-col>

			<v-responsive width="100%"></v-responsive>
			<v-col class="d-flex" cols="12">
				<v-card class="pa-2 ma-2" width="100%">
					<v-card-item>
						<v-card-title>Финансовые операции</v-card-title>



						<v-data-table class="elevation-2 mb-2  mt-2" :items="formattedFinancialTableItems"
							hide-default-footer :headers="financialTableHeaders"></v-data-table>
					</v-card-item>


				</v-card>
			</v-col>

		</v-row>
	</v-container>

	<fartsa-alerts></fartsa-alerts>
</template>





<script>
import { mutationTypes as alertMutationTypes } from "Store/modules/alerts/types";
import Alerts from "Views/base/Alerts";
import { createNamespacedHelpers } from "vuex";
import { namespace as alertsStoreNamespace } from "Store/modules/alerts";
import AlertModel from "Store/modules/alerts/models/alertModel";
import userPanelApi from '../api/adminPanel';
import moment from 'moment';




const dateTimeFormat = "DD.MM.YYYY HH:mm";
const alertsModule = createNamespacedHelpers(alertsStoreNamespace);

export default {
	data: () => (

		{
			searchItems: [],
			searchText: '',
			promocodeTableData: [],
			financialTableData: [],
			userData: {},
			userBeneficiaryData: {},
			userId: '',
			financialTableHeaders: [
				{ title: 'id', key: 'id', align: 'center' },
				{ title: 'Сумма', key: 'amountChange', align: 'center' },
				{ title: 'Описание', key: 'description', align: 'center' },
				{ title: 'Заморожен до', key: 'date', align: 'center' },
				{ title: 'ID сделки', key: 'nominalDealId', align: 'center' },
				{ title: 'Статус номинальной сделки', key: 'nominalOperationCompleted', align: 'center' },
				{ title: 'Статус', key: 'status', align: 'center' },
			],
			promocodeTableHeaders: [
				{ title: 'Id Промокода', key: 'promocodeId', align: 'center' },
				{ title: 'Id Промоакции', key: 'promoactionId', align: 'center' },
				{ title: 'Дата', key: 'date', align: 'center' },
				{ title: 'Статус', key: 'accountPromocodeStatus', align: 'center' },
			],
			userLegalEntity: {},
		}
	),
	methods: {
		...alertsModule.mapMutations({
			addErrorAlert: alertMutationTypes.ADD_ERROR_ALERT
		}),
		async getUsersBySeachText(e) {
			const res = await userPanelApi.searchUser(e.target.value)
			this.searchItems = JSON.parse(JSON.stringify(res))

		},
		async setUserId(userId) {
			this.searchText = userId
			this.promocodeTableData = await userPanelApi.getAmbassadorPromocodes(userId)
			this.financialTableData = await userPanelApi.getAmbassadorTransactions(userId)
			this.userData = await userPanelApi.getAmbassadorProfile(userId)
			this.userLegalEntity = this.userData.legalEntity
			this.userBeneficiaryData = await userPanelApi.getAmbassadorBeneficiary(userId)
			this.searchText = `${this.userData.fcs} id: ${userId}`
			this.userId = userId
		},
		userStatus(isActive) {
			switch (isActive) {
				case true: return 'Активен'
				case false: return 'Удален'
				default: return ''
			}
		},
		userBankAttachStatus(bankAttached) {
			switch (bankAttached) {
				case true: return 'success'
				case false: return 'error'
				default: return ''
			}

		},
		userType(type) {
			switch (type) {
				case 1: return this.$t("order.userTypes.individual")
				case 2: return this.$t("order.userTypes.selfEmployed")
				case 3: return this.$t("order.userTypes.individualEntrepreneur")
				default: return ''
			}
		},
		accountOperationStatus(status) {
			switch (status) {
				case 0: return 'В процессе'
				case 1: return 'Успешно'
				case 2: return 'Отклонен'
				case 3: return 'Ожидание'
				default: return ''
			}

		},
		accountPromocodeStatus(status) {
			switch (status) {
				case 1: return 'Созднан, неактивный'
				case 2: return 'Активный, готов к использованию'
				case 3: return 'Выпущен клиенту'
				case 4: return 'Зарегистрирован за клиентом'
				case 5: return 'В процессе погашения'
				case 6: return 'Погашен'
				case 7: return 'Отменен'
				case 0: return ''
				default: return ''
			}
		},
		legalEntityStatusTypeColor(type) {
			switch (type) {
				case 0: return 'warning'
				case 1: return 'error'
				case 2: return 'success'
				case 3: return ''
				default: return ''
			}

		},
		legalEntityStatusTypeIcon(type) {
			switch (type) {
				case 0: return 'mdi-account-clock'
				case 1: return 'mdi-account-cancel'
				case 2: return 'mdi-account-check'
				case 3: return ''
				default: return ''
			}

		},





	},
	computed: {
		formattedPromocodeTableItems() {
			return this.promocodeTableData.map(x => {
				return {
					...x,
					date: moment(x.operationTime).format(dateTimeFormat),
					accountPromocodeStatus: this.accountPromocodeStatus(x.status)

				};
			});
		},
		formattedFinancialTableItems() {
			return this.financialTableData.map(x => {
				return {
					...x,
					date: x.frozenTo ? moment(x.frozenTo).format(dateTimeFormat) : null,
					status: this.accountOperationStatus(x.status)

				};
			});
		},
	},
	components: {
		"fartsa-alerts": Alerts,

	},
	async mounted() {
		try {
			// this.items = await transactionsApi.getTransactions()


		} catch (e) {
			this.addErrorAlert(new AlertModel({ text: this.$t("order.errors.orderError") }));
		}
	}

}



</script>