import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../views/base/Layout.vue'
import Auth from '../views/Auth.vue'
import Order from 'Views/Order.vue'
import Transactions from 'Views/Transactions.vue'
import UserPanel from 'Views/UserPanel.vue'
import addAuthGuard from "Router/guards/auth";



const routes = [
  {
    path: "/",
    name: 'Home',
    redirect: { name: 'order' },
    meta: { requiresAuth: true },
    component: Layout,
    children: [
      {
        path: "/order",
        name: 'order',
        meta: { requiresAuth: true },
        component: Order,
      },
      {
        path: "/transactions",
        name: 'transactions',
        meta: { requiresAuth: true },
        component: Transactions,
      },
      {
        path: "/userPanel",
        name: 'userPanel',
        meta: { requiresAuth: true },
        component: UserPanel,

      }
    ]
  },
  {
    path: "/login",
    name: 'login',
    component: Auth,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

addAuthGuard({ router });

export default router
