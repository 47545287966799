import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import configureApi from "Api/index";

configureApi();

createApp(App).use(store).use(router).use(vuetify).use(i18n).mount('#app')
