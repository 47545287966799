import apiClient from "../api/client/apiClient";
import urls from './config/urls'


export default {
	async searchUser(searchText) {
		try {
			const result = await apiClient.get({ url: urls.adminPanel.searhUser.replace('{searchText}', searchText) })
			return result
		} catch (error) {
			throw error
		}
	},
	async getAmbassadorProfile(userId) {
		try {
			const result = await apiClient.get({ url: urls.adminPanel.getAmbassadorProfile.replace('{userId}', userId) })
			return result
		} catch (error) {
			throw error
		}
	},
	async getAmbassadorBeneficiary(userId) {
		try {
			const result = await apiClient.get({ url: urls.adminPanel.getAmbassadorBeneficiary.replace('{userId}', userId) })
			return result
		} catch (error) {
			throw error
		}
	},
	async getAmbassadorTransactions(userId) {
		try {
			const result = await apiClient.get({ url: urls.adminPanel.getAmbassadorTransactions.replace('{userId}', userId) })
			return result
		} catch (error) {
			throw error
		}
	},
	async getAmbassadorPromocodes(userId) {
		try {
			const result = await apiClient.get({ url: urls.adminPanel.getAmbassadorPromocodes.replace('{userId}', userId) })
			return result
		} catch (error) {
			throw error
		}
	},

}