<template>
	<v-container>
		<fartsa-alert-snackbar :text="errorAlert.text" @close="onCloseErrorAlert(index)" color="error"
			:timeout="errorAlert.timeout" v-for="(errorAlert, index) in errorAlerts" :key="index">
		</fartsa-alert-snackbar>
		<fartsa-alert-snackbar :text="infoAlert.text" @close="onCloseInfoAlert(index)" color="info"
			:timeout="infoAlert.timeout" v-for="(infoAlert, index) in infoAlerts" :key="index">
		</fartsa-alert-snackbar>
	</v-container>
</template>

<script>
import AlertSnackbar from "Components/snackbars/AlertSnackbar";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/modules/alerts";
import { mutationTypes } from "Store/modules/alerts/types";

const { mapState, mapMutations } = createNamespacedHelpers(namespace);

export default {
	name: "fartsa-alerts",
	computed: {
		...mapState({
			errorAlerts: state => state.errorAlerts,
			infoAlerts: state => state.infoAlerts
		})
	},
	methods: {
		...mapMutations({
			onCloseErrorAlert: mutationTypes.REMOVE_ERROR_ALERT,
			onCloseInfoAlert: mutationTypes.REMOVE_INFO_ALERT
		})
	},
	components: {
		"fartsa-alert-snackbar": AlertSnackbar
	}
};
</script>
