import AlertModel from "Store/modules/alerts/models/alertModel";

export default class AlertsStateModel {
	/**
	 *
	 * @param {AlertModel[]} errorAlerts
	 * @param {AlertModel[]} infoAlerts
	 */
	constructor({ errorAlerts, infoAlerts }) {
		this.errorAlerts = errorAlerts;
		this.infoAlerts = infoAlerts;
	}
}