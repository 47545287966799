
import axios from "axios";
import { getHeaders } from "../config/headers";


class ApiClient {
	constructor() {
	}

	async get({ url, payload = null }) {
		try {

			let { data } = payload === null ? await axios.get(`${url}`, {
				headers: getHeaders()
			}) : await axios.get(`${url}`, payload)


			return data;
		} catch (e) {

			throw e;
		}
	}

	async put({ url, payload }) {
		try {


			let { data } = await axios.put(url, payload, {
				headers: getHeaders()
			});



			return data;
		} catch (e) {

			throw e;
		}
	}

	async delete({ url }) {
		try {


			let { data } = await axios.delete(url, {
				headers: getHeaders()
			});



			return data;
		} catch (e) {

			throw e;
		}
	}

	async post({ url, payload, headers }) {
		try {


			let { data } = await axios.post(url, payload, {
				headers: {
					...getHeaders(),
					...headers
				}
			});



			return data;
		} catch (e) {

			throw e;
		}
	}
}

export default new ApiClient();

