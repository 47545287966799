import axios from "axios";
import _authService from "Services/authService";

const addAuthInterceptor = () => {
	axios.interceptors.response.use((response) => {
		return response;
	}, async (error) => {
		if (error.response.status !== 401)
			return Promise.reject(error);

		try {
			let token = await _authService.tryUpdateAccessToken();
			const config = error.config;
			config.headers["Authorization"] = `Bearer ${token}`;
			return await axios.request(config);
		} catch (ex) {
			await _authService.logout();
			await Vue.prototype.$router.push({ name: "Home" });
			return Promise.reject(error);
		}
	});
};

export default addAuthInterceptor;
