
import { createI18n } from "vue-i18n"
import locales from "./locales";





const ruLocaleAlias = "ru";
const enLocaleAlias = "ru";


const getLocale = (lang, lcs) => {
	let locale = {};

	if (Object.prototype.hasOwnProperty.call(lcs, lang)) {
		locale = lcs[lang];
	} else {
		Object.keys(lcs).forEach((key) => {
			locale[key] = getLocale(lang, lcs[key]);
		});
	}

	return locale;
};

const messages = {
	ru: Object.assign({}, getLocale(ruLocaleAlias, locales))
};

const i18n = createI18n({
	locale: ruLocaleAlias,
	fallbackLocale: enLocaleAlias,
	messages,
})

export default i18n
