
export default class LegalEntityDadataModel {
	constructor({ name, inn, ogrn, kpp, address, registrationDate, firstName, lastName, middleName, type, dateOfBirth, documentIssuedBy, documentIssuedByCode, documentIssuedDateAt, documentNumber, addressRegistration }) {
		this.inn = inn;
		this.name = name;
		this.ogrn = ogrn
		this.kpp = kpp
		this.address = address
		this.registrationDate = registrationDate
		this.firstName = firstName
		this.lastName = lastName
		this.middleName = middleName
		this.type = type
		this.dateOfBirth = dateOfBirth
		this.documentIssuedBy = documentIssuedBy
		this.documentIssuedByCode = documentIssuedByCode
		this.documentIssuedDateAt = documentIssuedDateAt
		this.documentNumber = documentNumber
		this.addressRegistration = addressRegistration




	}
	static empty() {
		return new LegalEntityDadataModel({ name: '', inn: '', ogrn: '', kpp: '', address: '', registrationDate: '', firstName: '', middleName: '', lastName: '', dateOfBirth: '', documentIssuedBy: '', documentIssuedByCode: '', documentIssuedDateAt: '', documentNumber: '', addressRegistration: '' })
	}
}
