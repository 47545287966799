export default {
	documents: {
		innCercificate: {
			ru: 'Инн сертификат'
		},
		passport: {
			ru: 'Паспорт'
		},
		order: {
			ru: 'Приказ'
		},
		signature: {
			ru: 'Электронная подпись'
		},
		ogrnip: {
			ru: 'ОГРНИП'
		},
		SignedContract: {
			ru: 'Подписанный документ'
		}

	},
	userTypes: {
		individual: {
			ru: 'Физическое лицо'
		},
		selfEmployed: {
			ru: 'Самозанятый гражданин'
		},
		individualEntrepreneur: {
			ru: 'Индивидуальный предприниматель'
		}
	},
	subtitles: {
		inn: {
			ru: 'ИНН'
		},
		addressRegistration: {
			ru: 'Адрес регистрации'
		},
		dateOfBirth: {
			ru: 'Дата рождения'
		},
		documentNumber: {
			ru: 'Серия и номер паспорта'
		},
		documentIssuedBy: {
			ru: 'Кем выдан'
		},
		documentIssuedByCode: {
			ru: 'Код подразделения'
		},
		documentIssuedDateAt: {
			ru: 'Дата выдачи'
		},
		ogrn: {
			ru: 'ОГРН'
		},
		registrationDate: {
			ru: 'Дата регистрации'
		},
		address: {
			ru: 'Адрес'
		}
	},
	buttons: {
		deny: {
			ru: 'Отказать'
		},
		approve: {
			ru: 'Одобрить'
		}

	},
	titles: {
		noBids: {
			ru: 'На данный момент актуальные заявки отсутствуют!'
		}

	},
	errors: {
		orderError: {
			ru: 'Произошла ошибка, повторите попытку'
		}

	}

}