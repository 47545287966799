import apiClient from '@/api/client/apiClient';
import urls from './config/urls'


export default {
	async getTransactions() {
		try {
			const result = await apiClient.get({ url: urls.transacions.getTransactions })
			return result
		} catch (error) {

			throw error

		}
	},

}

