<template>
	<v-data-table class="elevation-2 mb-6  mt-6" :items="items" :headers="headers"></v-data-table>
	<fartsa-alerts></fartsa-alerts>
</template>





<script>
import { mutationTypes as alertMutationTypes } from "Store/modules/alerts/types";
import Alerts from "Views/base/Alerts";
import { createNamespacedHelpers } from "vuex";
import { namespace as alertsStoreNamespace } from "Store/modules/alerts";
import AlertModel from "Store/modules/alerts/models/alertModel";
import transactionsApi from '../api/transactions';




const alertsModule = createNamespacedHelpers(alertsStoreNamespace);

export default {
	data: () => (

		{
			items: [],
			headers: [
				{ title: 'Номер аккаунта', key: 'payerAccountNumber', align: 'end' },
				{ title: 'Сумма', key: 'amount', align: 'end' },
				{ title: 'Назначение платежа', key: 'paymentPurpose', align: 'end' },
				{ title: 'Инн', key: 'payerInn', align: 'end' },
				{ title: 'ID сделки', key: 'operationId', align: 'end' },
				{ title: 'ФИО', key: 'payerName', align: 'end' },
				{ title: 'Кнопка', key: 'iron', align: 'end' },
			],
		}
	),
	methods: {
		...alertsModule.mapMutations({
			addErrorAlert: alertMutationTypes.ADD_ERROR_ALERT
		}),

	},
	components: {
		"fartsa-alerts": Alerts,

	},
	async mounted() {
		try {
			this.items = await transactionsApi.getTransactions()

		} catch (e) {
			this.addErrorAlert(new AlertModel({ text: this.$t("order.errors.orderError") }));
		}
	}

}



</script>