import AlertsStateModel from "../alerts/models/alertStateModel";
import { mutationTypes } from "Store/modules/alerts/types";
import AlertModel from "Store/modules/alerts/models/alertModel";

const namespace = "alerts";

const state = new AlertsStateModel({
	errorAlerts: [],
	infoAlerts: []
});

const getters = {};

const actions = {};

const mutations = {
	/**
	 * @param {AlertsStateModel} state
	 * @param {AlertModel} payload
	 */
	[mutationTypes.ADD_ERROR_ALERT](state, payload) {
		state.errorAlerts.push(payload);
	},
	/**
	 * @param {AlertsStateModel} state
	 * @param index
	 */
	[mutationTypes.REMOVE_ERROR_ALERT](state, index) {
		state.errorAlerts.splice(index, 1);
	},
	/**
	 * @param {AlertsStateModel} state
	 * @param {AlertModel} payload
	 */
	[mutationTypes.ADD_INFO_ALERT](state, payload) {
		state.infoAlerts.push(payload);
	},
	/**
	 * @param {AlertsStateModel} state
	 * @param index
	 */
	[mutationTypes.REMOVE_INFO_ALERT](state, index) {
		state.infoAlerts.splice(index, 1);
	}
};

export {
	namespace, state, getters, actions, mutations
};

export default {
	namespace, state, getters, actions, mutations
};